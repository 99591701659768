import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Translation } from '@translations';
import { TierType } from '@globals';
import { ReactComponent as AlertIcon } from '../assets/Alert.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';

function getTextPath(
  dialogPricingEnabled: boolean,
  currentPlan: string | null | undefined,
): Record<'title' | 'description' | 'button', Translation> {
  if (!dialogPricingEnabled)
    return currentPlan !== TierType.trial
      ? {
          title:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribersPayment.title',
          description:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribersPayment.description',
          button:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribersPayment.button',
        }
      : {
          title:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribers.title',
          description:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribers.description',
          button:
            'pages.Pricing.dialogs.PlanLimitReachedDialog.subscribers.button',
        };
  return {
    title: 'pages.Pricing.dialogs.PlanLimitReachedDialog.dialogues.title',
    description:
      currentPlan !== TierType.trial
        ? 'pages.Pricing.dialogs.PlanLimitReachedDialog.dialogues.description.included'
        : 'pages.Pricing.dialogs.PlanLimitReachedDialog.dialogues.description.free',
    button: 'pages.Pricing.dialogs.PlanLimitReachedDialog.dialogues.button',
  };
}

interface PlanLimitReachedDialogProps {
  onClose(): void;
  onSuccess(): void;
  dialogPricingEnabled?: boolean;
  nextPlanText?: string;
  currentPlanText?: string;
  currentPlanType?: string | null;
  nextPlanLimit: any;
  planLimit: any;
  mobileAdaptive?: boolean;
}

/**
 * Modal when the free plan limit is reached
 */
export const PlanLimitReachedDialog: React.FC<PlanLimitReachedDialogProps> = ({
  onClose,
  onSuccess,
  dialogPricingEnabled,
  nextPlanText,
  currentPlanText,
  currentPlanType,
  nextPlanLimit,
  planLimit,
}) => {
  const { t } = useSafeTranslation();
  const textPath = getTextPath(Boolean(dialogPricingEnabled), currentPlanType);

  return (
    <DefaultPricingDialog
      title={t(textPath.title, { currentPlan: currentPlanText })}
      icon={<AlertIcon />}
      description={t(textPath.description, {
        planLimit,
        nextPlan: nextPlanText,
        nextPlanLimit,
        currentPlan: currentPlanText,
      })}
      button={
        <DefaultPricingBtn intent="primary" onClick={onSuccess}>
          {t(textPath.button)}
        </DefaultPricingBtn>
      }
      onClose={onClose}
    />
  );
};
